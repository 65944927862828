import React from "react";
import Image from "next/image";

const MobileSmall = ({ ...props }) => {

	const prop = props;

	return (
		<div className="card-small">

			<a href={prop.link} className="article-image-container">
				<Image
					src={prop.image}
					width={94}
					height={94}
					quality={70}
					objectFit="cover"
					layout="fixed"
					alt="article"
				/>
			</a>
			<div className="section-right">
				<a href={prop.catSlug} className="tag">
					{prop.category}
				</a>
				<a href={prop.link} className="title">{prop.title}</a>
				<div className="author">
					<a href={prop.authorSlug} className="author-image-container">
						<Image
							src={prop.authorImage}
							alt="author"
							layout="fixed"
							objectFit="cover"
							width={17}
							height={17}
							quality={70}
						/>
					</a>
					<p className="author-name">
						<a href={prop.authorSlug}>
							{prop.authorName}
						</a>
						{
							!prop.disabledDate && (
								<>
									・
									{ prop.date }
								</>
							)
						}
					</p>
				</div>
			</div>

			<style jsx>
				{`
          .section-right {
            display: flex;
            flex-flow: column;
          }
          .card-small {
            display: flex;
            flex-flow: row;
            gap: 16px;
          }
          .author {
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 6px 0 0 0;
          }
          .article-image-container {
            width: 94px;
            height: 94px;
            flex-shrink: 0;
          }
          .image-article {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .tag {
            font-family: Roboto;
            font-size: 10px;
            font-weight: 700;
            line-height: 12px;
            letter-spacing: 0em;
            text-align: left;
            color: #E66983;
            text-transform: uppercase;
            margin: 0 0 8px 0;
          }
          .title {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0em;
            text-align: left;
            color: #1A1E23;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .author-image-container {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            overflow: hidden;
          }
          .author-name {
            font-family: Roboto;
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0em;
            text-align: left;
            color: #919EAB;
            text-transform: uppercase;
            margin: 0;
          }

          .author-name a {
            color: #919EAB;
          }
        `}
			</style>
		</div>
	);

};

export default MobileSmall;
